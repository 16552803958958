body {
  margin: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Lato", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
div {
  font-size: 18px;
}
a {
  color: inherit;
}
:root {
  --gray: #2b3035;
  --white: #ffffff;
  --gray--light: #f0f0f0;
  --orange: #ffb600;
  --black: #000;
}
