.form-control {
  box-shadow: none;
  border: 1px solid #dadada;
  padding: 5px 20px;
  /* height: 44px; */
  background: none;
  color: #959595;
  font-size: 14px;
  border-radius: 0 !important ;
}
label {
  font-weight: 400;
  display: inline-block;
  margin-bottom: 0.5rem;
  color: #555;
}
.ts-service-box-bg {
  background: #252525;
  color: #fff;
  padding: 30px;
}
.ts-service-icon.icon-round {
  color: #fff;
  background: #ffb600;
  text-align: center;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  line-height: 60px;
  margin-bottom: 10px;
  position: relative;
  float: none;
  padding: 10px 12px 14px 12px;
}
.text-right {
  text-align: right !important;
}
