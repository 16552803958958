.services--title {
  font-weight: 900;
  font-size: 36px;
  line-height: 46px;
  margin: 0 0 60px;
  color: #212121;
  text-align: center;
  background-color: hsl(0, 0%, 94%);
  padding: 10px;
}
/* .services-card-title {
  border-top: 1px solid #ffb600;
  line-height: 1.8;
  padding-left: 15px;
} */
.services-card-logo {
  color: var(--orange);
}
