.apropos--title {
  font-weight: 300;
  text-transform: uppercase;
  font-size: 22px;
  line-height: normal;
  margin: 0;
}
.apropos--text {
  text-rendering: optimizeLegibility;
  line-height: 26px;
  font-size: 18px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.quality-item {
  box-shadow: none;
  /* border: 1px solid #545454; */
  padding: 5px 20px;
  /* height: 44px; */
  background: none;
  font-size: 20px;
  border-radius: 0 !important ;
  display: flex;
  align-items: center;
  margin: 2px;
  margin-bottom: 10px;
  font-weight: 500;
  /* max-width: 500px !important; */
}
.yellow-icon {
  color: #212121; /* Set icon color to yellow */
  margin-right: 5px; /* Add space between icon and text */
}
@media (max-width: 767px) {
  /* Apply styles only on screens up to 767 pixels wide (small screens) */
  .quality-item {
    max-width: 95vw !important;
  }
}
.accordion-group {
  .card {
    border-radius: 0;
    margin-bottom: 10px;
    border-bottom: 1px solid #dfdfdf !important;
  }
  .card-body {
    padding: 15px 20px;
    img {
      max-width: 100px;
      margin-bottom: 10px;
    }
  }
  .card-header .item {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 13px 15px;
    padding-bottom: 10px;
    .yellow-icon {
      padding: 0px;
      padding-left: 10px;
      font-size: 32px;
    }
    /* &:before {
      font-family: "Font Awesome 5 Free";
      position: absolute;
      z-index: 0;
      font-size: 14px;
      right: 16px;
      padding: 3px 8px 1px;
      text-align: center;
      border-radius: 3px;
      top: 12px;
      content: "\f107";
      font-weight: 700;
      background-color: #333;
      color: #fff;
      transition: 0.3s;
    } */
  }
}
